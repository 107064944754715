import React from 'react'
import TransportNavBar from '../TransportNavBar';


const Profile = () => {
 
  return (
    <>
    <TransportNavBar/>
    <div>Profile</div>
    </>  
  )
}

export default Profile