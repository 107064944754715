import React from 'react'

const FooterNote = () => {
  return (
    <>
      {/* <div className="w-full flex  bg-[#0E1C35]">
        <div className="w-full flex items-center justify-center">
            <span className="text-white text-center md:text-2xl hover:text-white pt-[40px]">© 2019 Lift Media. All Rights Reserved. </span>
        </div>
      </div> */}
    </>
  )
}

export default FooterNote
